import Categories from './categories.svg'
import Combine from './combine.svg'
import Data from './data.svg'
import Folder from './folder.svg'
import HappyInbox from './happyinbox.svg'
import Ios from './ios.svg'
import Iphone from './iphone.svg'
import Oops from './oops.svg'
import Rollup from './rollup.svg'
import Slice from './slice_intel.svg'
import NielsenLogo from './nielsen_logo.svg'
import Unsubscribe from './unsubscribe.svg'
import SafeData from './SafeData.png'
import SafeData2x from './SafeData@2x.png'
import UnderstandingData from './UnderstandingData.png'
import UnderstandingData2x from './UnderstandingData@2x.png'
import DataUsage from './DataUsage.png'
import DataUsage2x from './DataUsage@2x.png'
import Wine from './wine.png'
import Wine2x from './wine@2x.png'
import Coffee from './coffee.png'
import Coffee2x from './coffee@2x.png'
import ChrisLin from './chris_lin.png'
import ChrisLin2x from './chris_lin@2x.png'
import CurvedTop from './curve_top.svg'
import AngledSlash from './angled-slash.png'
import AngledUnroll from './angled-unroll.png'
import Slide1 from './slide_1.png'
import Slide2 from './slide_2.png'
import LogoMark from './logo-mark.svg'

export {
  Categories,
  Combine,
  Data,
  Folder,
  HappyInbox,
  Ios,
  Iphone,
  Oops,
  Rollup,
  Slice,
  NielsenLogo,
  Unsubscribe,
  SafeData,
  SafeData2x,
  UnderstandingData,
  UnderstandingData2x,
  DataUsage,
  DataUsage2x,
  Wine,
  Wine2x,
  Coffee,
  Coffee2x,
  ChrisLin,
  ChrisLin2x,
  CurvedTop,
  AngledSlash,
  AngledUnroll,
  Slide1,
  Slide2,
  LogoMark,
}
